<template>
    <ion-page id="menu">
        <TermsModal v-if="showModal" :accepted="accepted" @closeModal="handleCloseModal" />
        <ion-tabs>
            <ion-router-outlet></ion-router-outlet>
            <ion-tab-bar selected-tab="how" slot="bottom">
                <ion-tab-button tab="tests.index" href="/app/tests">
                    <img src="@/assets/svg/testMenuLogo.svg" />
                    <ion-label>{{ $t('TESTS_AVAILABLE') }}</ion-label>
                    <ion-badge>{{
                        testsStore.state.testsState.count
                    }}</ion-badge>
                </ion-tab-button>
                <ion-tab-button tab="record.index" href="/app/record">
                    <img src="@/assets/svg/pointsMenuLogo.svg" />
                    <ion-label>{{ $t('MY_POINTS') }}</ion-label>
                </ion-tab-button>
                <ion-tab-button tab="what" href="/app/what">
                    <img src="@/assets/svg/whatMenuLogo.svg" />
                    <ion-label>WhAT!</ion-label>
                </ion-tab-button>
                <ion-tab-button tab="how" href="/app/how">
                    <img src="@/assets/svg/howMenuLogo.svg" />
                    <ion-label>{{ $t('Testers_shared_header_how') }}</ion-label>
                </ion-tab-button>
                <ion-tab-button tab="profile" href="/app/profile">
                    <img src="@/assets/svg/profileMenuLogo.svg" />
                    <ion-label>{{ $t('MY_ACCOUNT') }}</ion-label>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ion-page>
</template>

<script>
import { defineComponent, onBeforeMount, ref,inject } from 'vue'
import TermsModal from '@/components/TermsModal.vue'
import { useRouter } from 'vue-router'
import {
    IonIcon,
    IonLabel,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonBadge,
    IonRouterOutlet,
} from '@ionic/vue'
import { calendar, personCircle } from 'ionicons/icons'
import {
    bookOutline,
    checkboxOutline,
    heartOutline,
    checkmarkDoneCircleOutline,
    listOutline,
    personOutline,
} from 'ionicons/icons'
import testsStore from '@/store/tests.store'

export default defineComponent({
    components: {
        IonIcon,
        IonLabel,
        IonPage,
        IonTabBar,
        IonTabButton,
        IonTabs,
        IonBadge,
        IonRouterOutlet,
        TermsModal
    },
    setup() {
        const router = useRouter()
        const showModal = ref(false)
        const accepted = ref(false)
        const authStore = inject('authState')

        onBeforeMount(async () => {
            await testsStore.getTests(0)
            const { termsAccepted, mobileComplete, mobilePrefix, mobileNum, status } = await authStore.getUserData()
            showModal.value = termsAccepted === null || termsAccepted === false
            console.log(termsAccepted, showModal.value)
            if (mobileComplete == null || mobilePrefix == null || mobileNum == null) {
                router.push({ name: 'add-mobile' })
            } else if (status == 'PENDING_2FA') {
                await authStore.logUserOut()
                router.push({ name: 'login' })
            }   
        })

        function handleCloseModal() {
            showModal.value = false;
        }

        return {
            calendar,
            personCircle,
            bookOutline,
            checkboxOutline,
            heartOutline,
            checkmarkDoneCircleOutline,
            listOutline,
            personOutline,
            testsStore,
            handleCloseModal,
            showModal,
            accepted,
        }
    },
})
</script>

<style lang="scss">
    body{
        --ion-background-color: #fcfaff;
    }
</style>
<style lang="scss" scoped>
ion-tab-bar {
    height: 70px;
    > ion-tab-button {
        &.tab-selected {
            > ion-label {
                font-size: 10px;
                color: #8134ff;
                margin-top: 8px;
            }
        }
        > ion-label {
            font-size: 10px;
            font-family: 'Bariol';
            font-style: normal;
            font-weight: 700;
            color: #a6a6a6;
            margin-top: 8px;
        }
        > ion-badge {
            height: 20px;
            width: 20px;
            left: calc(50% + 5px);
            top: 10%;
            background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>

