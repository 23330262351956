<script setup>
import {
    IonCheckbox,
} from '@ionic/vue'
import { inject, ref, watchEffect } from 'vue'
import TermsAndConditions from '@/components/termsAndConditions.vue'

const authStore = inject('authState')
const emit = defineEmits(['closeModal']);

const props = defineProps({
    accepted: {
        type: Boolean,
        required: true
    },
})

const accepted = ref(props.accepted)
const termsNoReaded = ref(true)
const showTerms = ref(false)
const checkboxClicked = ref(false)
const showPopover = ref(false)

watchEffect(() => {
    if (!termsNoReaded) {
        showPopover.value = false
    }
}
)

const closeModal = () => {
    emit('closeModal');
}
const handleShowTerms = () => {
    showTerms.value = false
    termsNoReaded.value = false
    }
const handleClick = () => {
    if (termsNoReaded.value) {
        checkboxClicked.value = true;
        showPopover.value = true;
        setTimeout(() => {
            checkboxClicked.value = false;
        }, 500);
        setTimeout(() => {
            showPopover.value = false;
        }, 3000);
    }
}

const acceptTerms = async () => {
    accepted.value = true
    try {
        if (accepted.value) {
            await authStore.updateUserTermsAccepted()
            closeModal();
        }
    } catch (error) {
        console.log(error)
    }
}

</script>
<template>
    <div v-if="showTerms" class="iframe-container">
                <TermsAndConditions class="iframe" @closeModal="handleShowTerms() " isFromRegister />
            </div>

    <div class="modal">
        <div class="modal-content">
            <div class="header">
                <h2>{{ $t('Testers_signup_use_terms_modal_title_v2') }} {{ $t('Testers_signup_use_terms_v2') }}</h2>
                <p>{{ $t('Testers_signup_use_terms_modal_subtitle_v2') }}.</p>
            </div>
            <div class="content">
                <label @click="handleClick" class="checkbox--label">
                    <ion-checkbox :disabled="termsNoReaded" v-model="accepted">
                    </ion-checkbox>

                    <p>{{ $t('Testers_signup_use_terms_modal_accept_text_v2') }}</p>
                    <div class="popover-container">
                        <div :class="{ 'popover-show': termsNoReaded && showPopover }" class="popover-content">
                            <div class="arrow"></div>
                            {{ $t('Testers_signup_use_terms_modal_popover_text_v2') }}
                        </div>
                        <a :class="{ 'shake-effect': termsNoReaded && checkboxClicked }"
                            @click="showTerms = !showTerms"> {{
        $t('Testers_signup_use_terms_v2') }}
                        </a>
                    </div>
                </label>
                <button @click="acceptTerms" :disabled="!accepted" class="accept-button">{{ $t('Accept') }}</button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

p {
    color: black;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
}

.modal-content {
    max-width: 500px;
    /* Ancho máximo del contenido */
    width: 90%;
    /* Ajusta el ancho según sea necesario */
    padding: 20px;
    background-color: #fefefe;
    border: 1px solid #3e216c;
    border-radius: 10px;
}

.header {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.header h2 {
    margin-bottom: 10px;
    color: #3e216c;
}

.content {
    text-align: center;
}

label {
    display: block;
    margin-bottom: 10px;
}

.popover-container {
    position: relative;

}

.popover-content {
    display: none;
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translate(-50%);
    background-color: #888383;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    color: white;
    font-size: 10px;
    text-align: start;
    width: 278px;
}

.arrow {
    position: absolute;
    bottom: -10px;
    left: 25%;
    transform: translate(-25%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #888383;

}

@media (max-width:768px) {
    .popover-content {
        left: 0;
        transform: translate(0);
        width: 200px;

    }

    .arrow {
        left: 15%;
        transform: translate(-15%);
    }

}

.popover-show {
    display: block;
}

@media screen and (max-width:600px) {
    .header {
        h2 {
            font-size: larger;
        }

        p {
            font-size: medium;
        }
    }

    .content {
        font-size: 75%;

    }
}

.accept-button {
    width: 90%;
    margin: 0 auto;
    padding: 10px;
    background-color: #3e216c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;
    padding: 10px 20px;
    transition: box-shadow ease 0.1s, transform ease 0.1s;
    box-shadow: 0px 5px 0px 0px #a29bfe;
}

.accept-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    box-shadow: 0px 0px 0px 0px #a29bfe;

}

.accept-button:disabled:active {
    transform: translateY(0px);


}

.accept-button:disabled:hover {
    background-color: #ccc;
    cursor: not-allowed;
}

.accept-button:active {
    transform: translateY(5px);
    box-shadow: 0px 0px 0px 0px #a29bfe;
}

.accept-button:hover {
    background-color: #58309a;
}

/*************Checkbox */
/* Hide the default checkbox */
.checkbox--label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox--label {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    a {
        text-decoration: none;
        color: #58309a;
        transition: color ease 0.3s;
    }

    a:hover {
        color: #59309a7b;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 #59309a7b;
        rotate: 20deg;
    }

    50% {
        rotate: -20deg;
    }

    75% {
        box-shadow: 0 0 0 10px #59309a7b;
    }

    100% {
        box-shadow: 0 0 0 13px #59309a7b;
        rotate: 0;
    }
}

.shake-effect {
    animation: shake 0.5s;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}

.iframe-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

}

.iframe {
    width: 95%;
    height: 90%;
    border: 2px solid var(--ion-color-primary);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}
</style>
